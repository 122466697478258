import React from 'react';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import ContactMe from "./Pages/ContactMe";
import Projects from "./Pages/Projects";
import { useState } from 'react';



function App() {

  return (
   <>
   <Navbar />
      <Router>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/Contact' exact component={ContactMe} />
          <Route path='/Projects' exact component={Projects} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
