import React, { useState } from "react";
import "./Projects.css";
import nostalgia from "../components/Images/nostalgiaspace.png";
import resume from "../components/Images/lukehickmanweb.png";

const Project = ({ title, description, imageUrl, videoUrl }) => (
  <div className="project-container">
    {videoUrl ? (
      <iframe
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="project-video"
      ></iframe>
    ) : (
      <img src={imageUrl} alt={title} className="project-image" />
    )}
    <h3 style={{ marginTop: 16 }}>{title}</h3>
    <p style={{ marginTop: 8 }}>{description}</p>
  </div>
);


const Carousel = ({ projects, index, setIndex }) => (
  <div className="carousel">
    <button
      className="carousel-button"
      onClick={() => index > 0 && setIndex(index - 1)}
    >
      {"<"}
    </button>
    <Project {...projects[index]} />
    <button
      className="carousel-button"
      onClick={() => index < projects.length - 1 && setIndex(index + 1)}
    >
      {">"}
    </button>
  </div>
);

const Projects = () => {
  const [index, setIndex] = useState(0);

  const projects = [
    {
      title: "InstantGPT",
      description: "InstantGPT is a chrome extension that utilizes the power of OpenAI's ChatGPT to provide quick and helpful information on demand. The extension can be easily accessed by using the keyboard shortcut 'Ctrl Shift S', which brings up the assistant for users to ask questions and receive responses. Additionally, the extension allows users to control the API parameters in the settings, providing a personalized experience.       ",
      imageUrl: "https://via.placeholder.com/200x200.png?text=Project+1",
      videoUrl: "https://www.youtube.com/embed/YzEM2fvEd10", // Replace [video-id] with the actual ID of the YouTube video
    },
    {
      title: "Nostalgia Space",
      description: "NostalgiaSpace.com is a unique website that offers an interactive journey through time. Users can slide through different years to discover top music, movies, video games, and sports championships from each era. The website combines a user-friendly interface with a robust tech stack, including React, Node.js, AWS Dynamo DB, AWS API Gateway, and Firebase Hosting.",
      imageUrl: nostalgia,
    },
    {
      title: "LukeHickman.com",
      description: "The website you're on right now! Simple React website for displaying my resume.",
      imageUrl: resume,
    },
  ];

  return (
    <div className="app">
      <Carousel projects={projects} index={index} setIndex={setIndex} />
      <ul className="titles">
        {projects.map((project, i) => (
          <li key={i} className={index === i ? "selected" : ""} onClick={() => setIndex(i)}>
            {project.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Projects;
