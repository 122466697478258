import React from 'react';
import CV from "react-cv";
import me from "../components/Images/luke3.jpg";
import "../components/AboutMe.css"

const Home = () => {
    return (
    <div>
        <CV
            personalData={{
                name: 'Luke Hickman',
                title: 'Infrastructure Engineer',
                image: me,
                contacts: [
                    { type: 'location', value: 'Atlanta, GA' },
                    { type: 'website', value: 'lukehickman.com' },
                    { type: 'linkedin', value: 'linkedin.com/in/williamlhickman' },
                ]}}
            sections= {[{
                type: 'text',
                title: 'About Me',
                content: "As an Infrastructure DevOps Engineer, I specialize in transforming mission-critical applications by implementing cloud-native infrastructure and optimizing pipelines. Collaborating with diverse teams, I excel at gathering requirements and translating them into innovative features. Staying at the forefront of cloud and DevOps technologies, I’m passionate about tackling complex challenges through solution engineering.",
                icon: 'usertie'
            },
                {
                    type: 'experiences-list',
                    title: 'Experience',
                    description: '',
                    icon: 'archive',
                    items: [
                        {
                            title: 'Infrastructure Engineer - Team Lead',
                            company: 'Cigna Healthcare',
                            companyWebSite: 'https://www.cigna.com/',
                            companyMeta: '',
                            datesBetween: 'November 2021 - Present',
                            descriptionTags: ['Python', 'OpenShift','AWS', 'Ansible', 'Jenkins','Kubernetes']
                        },
                        {
                            title: 'Software Engineer',
                            company: 'Verra Mobility',
                            //description: 'Support and develop features for back-office application used to process speed camera infringements.',
                            companyWebSite: 'https://www.verramobility.com/about/',
                            companyMeta: '',
                            datesBetween: 'March 2021 - November 2021',
                            descriptionTags: ['Pega PRPC', 'Java', 'JavaScript', 'React', 'AWS', 'Docker','Kubernetes']
                        },
                        {
                            title: 'Software Developer',
                            company: 'Capgemini',
                            //description: 'Designed and delivered two financial request applications that were migrated from legacy platform to Pega BPM solution.',
                            companyWebSite: 'https://www.capgemini.com/us-en/',
                            companyMeta: '',
                            datesBetween: 'August 2019 - March 2021',
                            descriptionTags: ['Pega PRPC', 'Java', 'SQL']
                        },
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Education',
                    icon: 'graduation',
                    items: [
                        {
                            title: 'Bachelor of Science in Computer Information Systems',
                            authority: 'University of South Carolina',
                            authorityWebSite: 'https://sc.edu/',
                            rightSide: '2015 - 2019'
                        },
                        {
                            title: 'AWS Certified Solutions Architect - Associate',
                            authority: 'Amazon Web Services',
                            authorityWebSite: 'https://www.credly.com/badges/8a8bd551-9c92-41a2-9e05-a575d90ccaf6',
                            rightSide: 'Feb 2023'
                        },
                        {
                            title: 'AWS Certified Cloud Practitioner',
                            authority: 'Amazon Web Services',
                            authorityWebSite: 'https://www.credly.com/badges/2dc4957c-4760-4fae-8af8-9edd315050ab',
                            rightSide: 'Nov 2021'
                        },
                        {
                            title: 'Pega Certified Senior System Architect',
                            authority: 'Pegasystems',
                            authorityWebSite: 'https://academy.pega.com/verify-certification?email=luke.hickman%40capgemini.com',
                            rightSide: 'Aug 2020'
                        },
                        {
                            title: 'Pega Certified System Architect',
                            authority: 'Pegasystems',
                            authorityWebSite: 'https://academy.pega.com/verify-certification?email=luke.hickman%40capgemini.com',
                            rightSide: 'Sep 2019'
                        },
                    ]
                },
                {
                    type: 'tag-list',
                    title: 'Skills',
                    icon: 'rocket',
                    items: ['Pega PRPC', 'JavaScript', 'React', 'AWS', 'Java', 'Python','HTML','CSS','Docker','Kubernetes', 'SDLC','Business Process Design','DevOps','CI/CD']
                },
                {
                    type: 'tag-list',
                    title: 'Hobbies',
                    icon: 'cubes',
                    items: ['Golf', 'Brazilian Jiu-Jitsu','Biking', 'Traveling', 'Chess']
                }
            ]}
            branding={false}
        />
    <div className="footer">© 2024 Luke Hickman</div>
    </div>
    );
};


export default Home;