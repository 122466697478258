import React from 'react';
import ContactForm from "../components/ContactForm";

const About = () => {
    return (
        <ContactForm />
    )
}


export default About;