export const MenuItems = [
    {
        title: 'View LinkedIn',
        url: 'https://www.linkedin.com/in/williamlhickman/',
        cName: 'nav-links-mobile'
    },
    {
        title: 'Download Resume',
        url: 'https://drive.google.com/file/d/1LSFtgk9POGA0S_xlinM2SDX65JA2nLKj/view?usp=sharing',
        cName: 'nav-links-mobile'
    },
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: '/Projects',
        cName: 'nav-links'
    },
    {
        title: 'Contact Me',
        url: '/Contact',
        cName: 'nav-links'
    },
]